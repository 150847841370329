import { RegionCode } from '@kaa/api/providers';
import { AvailableLanguages } from '@kaa/common/enums';
import { FooterBrussels } from '@kaa/core-app/common/components/brussels/footer';
import {
  REGION_COMPLAINT_URL,
  REGION_CONDITIONS_URL,
  REGION_COOKIES_URL,
  REGION_NEWS_URL,
  REGION_PRIVACY_URL,
  REGION_QUESTION_URL,
} from '@kaa/core-app/providers/constants';
import { useLanguage } from '@kaa/core-app/providers/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import React from 'react';
import { useTranslation } from 'react-i18next';

type FooterProps = {
  regionCode: RegionCode;
  languages: AvailableLanguages[];
};

export const Footer = ({ regionCode, languages }: FooterProps) => {
  const { t } = useTranslation();
  const { language, changeLanguage } = useLanguage();

  return (
    <FooterBrussels
      title={t(i18nKeys.bl.brps.label)}
      url={t(i18nKeys.bl.brps.url)}
      copyright={t(i18nKeys.footer.copyright)}
      topLinks={[
        {
          url: t(REGION_QUESTION_URL[regionCode]),
          label: t(i18nKeys.footer.cta.question.label),
          modLarge: true,
        },
        {
          url: t(REGION_COMPLAINT_URL[regionCode]),
          label: t(i18nKeys.footer.cta.complaint.label),
          modSecondary: true,
          modLarge: true,
        },
      ]}
      bottomLinks={[
        {
          url: t(REGION_CONDITIONS_URL[regionCode]),
          label: t(i18nKeys.footer.nav.tc.label),
        },
        {
          url: t(REGION_PRIVACY_URL[regionCode]),
          label: t(i18nKeys.footer.nav.pp.label),
        },
        {
          url: t(REGION_COOKIES_URL[regionCode]),
          label: t(i18nKeys.footer.nav.cookies.label),
        },
        {
          url: t(REGION_NEWS_URL[regionCode]),
          label: t(i18nKeys.footer.nav.news.label),
        },
      ]}
      language={language}
      languages={languages}
      changeLanguage={changeLanguage}
      // TO HIDE UNTIL WE ARE COMPLIANT
      // anysurfer={(t(i18nKeys.bl.anysurfer.url), t(i18nKeys.bl.anysurfer.a11y))}
    />
  );
};

import { useAuthState } from '@kaa/auth/common';
import { RouteActive } from '@kaa/common/types';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  Icon,
  SwColumn,
  SwGrid,
  SwIcon,
  SwLayout,
  SwLink,
} from '@kaa/ui-flanders/components';
// @ts-ignore
import logo from '@kaa/ui-flanders/themes/_shared/images/Pluxee_Logo_White_RGB.png';
import React, { AllHTMLAttributes, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { dataTest } from '../../datatest/keys';
import { getRoute, Routes } from '../../routes';
import { AuthContext } from '../../types';
import {
  EventAction,
  sendMainNavigationEventToGTM,
} from '../../utils/google-analytics';
import './BxFunctionalHeader.style.scss';
import { BxFunctionHeaderTab } from './BxFunctionalHeader.types';
import { getTabs, isActiveTab } from './BxFunctionalHeader.utils';
import { BxFunctionalHeaderSupportMobile } from './BxFunctionalHeaderSupportMobile';

type FunctionalHeaderProps = AllHTMLAttributes<HTMLDivElement> & {
  activeRoute?: RouteActive<Routes> | null;
  closeNav?: () => void;
  className?: string;
};

export const BxFunctionalHeader = ({
  activeRoute,
  className = '',
  closeNav,
}: FunctionalHeaderProps) => {
  const { t } = useTranslation();
  const { logout } = useAuthState<AuthContext>();
  const { authUser } = useAuthState<AuthContext>();
  const [tabs, setTabs] = useState<BxFunctionHeaderTab[]>();

  useEffect(() => {
    if (activeRoute) {
      setTabs(getTabs(activeRoute.route.id, t));
    }
  }, [activeRoute, t]);

  if (!activeRoute) {
    return null;
  }

  return (
    <>
      <SwColumn className={`${className} site-nav`}>
        <h1 className="site-nav__logo">
          <img
            alt={t(i18nKeys.bl.brps.label)}
            className="site-nav__image"
            src={logo}
          />
        </h1>
        <SwLayout>
          <SwGrid modVCenter>
            <SwColumn className="vl-nav">
              <SwGrid>
                <SwColumn>
                  <nav className="site-nav__navigation">
                    <SwColumn
                      className="site-nav__navigation-wrapper"
                      width="10"
                      widthM="12"
                    >
                      <ul>
                        {tabs?.map((tab) => {
                          const route = getRoute(tab.id, {
                            params: activeRoute.params,
                          });
                          return (
                            <li
                              key={`tab-${tab.id}`}
                              className={
                                isActiveTab(tab, activeRoute) ? 'active' : ''
                              }
                            >
                              <SwLink
                                to={route.path}
                                onClick={() => {
                                  sendMainNavigationEventToGTM(
                                    EventAction.CLICK,
                                    tab.id,
                                  );
                                }}
                              >
                                {tab.header}
                                {tab.children && (
                                  <SwIcon
                                    className="showMobile navItem-icon"
                                    icon={Icon.ARROW_DOWN}
                                  />
                                )}
                              </SwLink>
                              {tab.children && (
                                <ul className="site-nav__subnavigation">
                                  {tab.children.map((child) => {
                                    const tabChildRoute = getRoute(child.id, {
                                      params: activeRoute.params,
                                    });
                                    return (
                                      <li
                                        key={`tab-${tab.id}-child-${child.id}`}
                                        className={
                                          isActiveTab(child, activeRoute)
                                            ? 'active'
                                            : ''
                                        }
                                      >
                                        <SwLink
                                          onClick={() => {
                                            if (closeNav) {
                                              closeNav();
                                            }
                                            sendMainNavigationEventToGTM(
                                              tab.id,
                                              child.id,
                                            );
                                          }}
                                          to={tabChildRoute.path}
                                        >
                                          {child.header}
                                        </SwLink>
                                      </li>
                                    );
                                  })}
                                </ul>
                              )}
                            </li>
                          );
                        })}
                        <BxFunctionalHeaderSupportMobile />
                        {authUser && (
                          <li className="logout">
                            <SwLink
                              title={t(i18nKeys.navigation.logout)}
                              data-testid={dataTest.header.linkLogout}
                              onClick={() => {
                                sendMainNavigationEventToGTM(
                                  EventAction.CLICK,
                                  EventAction.LOG_OUT,
                                );
                                if (closeNav) {
                                  closeNav();
                                }
                                close();
                                logout();
                              }}
                            >
                              <SwIcon
                                className="showMobile navItem-icon"
                                icon={Icon.LOGOUT}
                              />
                              {t(i18nKeys.navigation.logout)}
                            </SwLink>
                          </li>
                        )}
                      </ul>
                    </SwColumn>
                  </nav>
                </SwColumn>
              </SwGrid>
            </SwColumn>
          </SwGrid>
        </SwLayout>
      </SwColumn>

      <div className="rainbow">
        <div className="rainbow__1" />
        <div className="rainbow__2" />
        <div className="rainbow__3" />
        <div className="rainbow__4" />
        <div className="rainbow__5" />
        <div className="rainbow__6" />
        <div className="rainbow__7" />
      </div>
    </>
  );
};

import { RegionCode } from '@kaa/api/providers';
import { AvailableLanguages } from '@kaa/common/enums';
import {
  HeaderBrussels,
  HeaderLogo,
  HeaderMenuButton,
  HeaderSupport,
  HeaderSwitchLanguage,
} from '@kaa/core-app/common/components/brussels/header/';
import { ActiveRouteProvider } from '@kaa/core-app/providers/components';
import { BxFunctionalHeader } from '@kaa/core-app/providers/components/functional-header-bl/BxFunctionalHeader';
import {
  REGION_CONTACT_US_URL,
  REGION_FAQ_URL,
  PASS_URL,
} from '@kaa/core-app/providers/constants';
import { getPath, Routes } from '@kaa/core-app/providers/routes';
import { useLanguage } from '@kaa/core-app/providers/utils';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import { Icon } from '@kaa/ui-flanders/components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HeaderLoginLink } from './header/HeaderLoginLink';
import { HeaderProfile } from './header/HeaderProfile';

type HeaderProps = {
  regionCode: RegionCode;
  languages: AvailableLanguages[];
};

export const Header = ({ regionCode, languages }: HeaderProps) => {
  const { t } = useTranslation();
  const { language, changeLanguage } = useLanguage();

  const [mobileNav, showMobileNav] = useState(false);

  return (
    <ActiveRouteProvider>
      {({ activeRoute }) => {
        return (
          <HeaderBrussels
            key={language}
            mobileNav={mobileNav}
            functionalHeader={
              <BxFunctionalHeader
                closeNav={() => showMobileNav(false)}
                activeRoute={activeRoute}
              />
            }
          >
            <HeaderLogo
              publicUrl={t(i18nKeys.bl.brps.url)}
              homeUrl={getPath(Routes.DASHBOARD)}
              title={t(i18nKeys.bl.brps.label)}
              titleMobile={t(i18nKeys.bl.general.region.name)}
              mainLogoAlt={t(i18nKeys.bl.brps.label)}
              logoAlt="Pluxee"
            />
            <HeaderSupport
              title={t(i18nKeys.header.support.title)}
              subtitle={t(i18nKeys.header.support.subtitle)}
              popoverTitle={t(i18nKeys.header.support.title)}
              popoverIcon={Icon.QUESTION_MARK_FILLED}
              links={[
                {
                  icon: Icon.PHONE,
                  url: `tel:${t(i18nKeys.bl.general.helpCenter.phone)}`,
                  label: t(i18nKeys.header.support.contactList.callus.title, {
                    phoneNumber: t(i18nKeys.bl.general.helpCenter.phone),
                  }),
                  description: t(
                    i18nKeys.bl.header.support.contactList.callus.description,
                  ),
                },
                {
                  target: '_blank',
                  icon: Icon.EMAIL,
                  url: t(REGION_CONTACT_US_URL[regionCode]),
                  label: t(i18nKeys.header.support.contactList.contactus.title),
                  description: t(
                    i18nKeys.header.support.contactList.contactus.description,
                  ),
                },
                {
                  target: '_blank',
                  icon: Icon.LOCATION,
                  url: t(PASS_URL[regionCode]),
                  label: t(i18nKeys.header.support.contactList.pass.title),
                  description: t(
                    i18nKeys.header.support.contactList.pass.description,
                  ),
                },
                {
                  target: '_blank',
                  icon: Icon.QUESTION_MARK,
                  url: t(REGION_FAQ_URL[regionCode]),
                  label: t(
                    i18nKeys.header.support.contactList.helpCenter.title,
                  ),
                  description: t(
                    i18nKeys.header.support.contactList.helpCenter.description,
                  ),
                },
              ]}
            />
            <HeaderSwitchLanguage
              language={language}
              languages={languages}
              changeLanguage={changeLanguage}
            />
            {activeRoute?.route.id !== Routes.LOGIN && (
              <>
                <HeaderProfile />
                <HeaderLoginLink />
              </>
            )}
            <HeaderMenuButton
              aria-label={t(i18nKeys.a11y.ariaLabel.menu)}
              aria-expanded={mobileNav}
              onClick={() => showMobileNav(!mobileNav)}
            />
          </HeaderBrussels>
        );
      }}
    </ActiveRouteProvider>
  );
};
